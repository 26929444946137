import "core-js/modules/es.array.push.js";
import { getUserPage, importStudent, getList, deleteUser, resetPassword } from "@/api/api";
export default {
  name: "studentList",
  data() {
    return {
      tableData: [],
      dataInfo: [],
      Info: null,
      dialogFormVisible: false,
      schoolList: [],
      gradeList: [],
      teamListRest: [],
      teamList: [],
      pageInfo: {
        pageSize: 10,
        type: 4,
        name: '',
        user_no: '',
        current: 1,
        total: 0,
        school_id: '',
        grade_level: '',
        class_id: ''
      },
      exportIds: ''
    };
  },
  mounted() {
    this.getList();
    this.getAllList();
  },
  methods: {
    reset() {
      this.pageInfo.name = '';
      this.pageInfo.user_no = '';
      this.pageInfo.school_id = '';
      this.pageInfo.grade_level = '';
      this.pageInfo.class_id = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    getList() {
      getUserPage(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    getAllList() {
      let data = {};
      getList(data, 'School').then(res => {
        this.schoolList = res.data;
      });
      getList(data, 'Grade').then(res => {
        this.gradeList = res.data;
      });
      getList(data, 'Clazz').then(res => {
        this.teamListRest = res.data;
      });
    },
    checkTeam() {
      if (this.pageInfo.school_id) {
        var teamList = [];
        this.teamListRest.find(res => {
          if (res.school_id == this.pageInfo.school_id) {
            if (this.pageInfo.grade_level) {
              if (res.grade_level == this.pageInfo.grade_level) {
                teamList.push(res);
              }
            } else {
              teamList.push(res);
            }
          }
        });
        console.log(teamList);
        this.teamList = teamList;
      }
    },
    showEdit(row) {
      this.dialogFormVisible = true;
      this.Info = row;
      let data = '';
      if (row) {
        data = JSON.stringify(this.Info);
      }
      this.$root.useRouter.push({
        path: "/user/userEdit",
        query: {
          Info: data,
          type: 4
        }
      });
    },
    showRenew(id) {
      this.dialogFormVisible1 = true;
      this.$refs.renewDiv.getInfo(id); //调用子组件方法
    },
    closeDiv() {
      this.dialogFormVisible = false;
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    },
    beforeAvatarUpload(e) {
      let formData = new FormData();
      formData.append('file', e);
      importStudent(formData).then(res => {
        this.$root.ElMessageBox.alert(res.msg, {
          dangerouslyUseHTMLString: true
        }); //dangerouslyUseHTMLString: true 设置为html处理
        if (res.success) {
          this.search();
        }
      });
    },
    resetPassword(id) {
      resetPassword({
        id: id
      }).then(res => {
        this.$root.msgResut(res);
      });
    },
    deleteUser(id) {
      deleteUser({
        id: id
      }).then(res => {
        this.$root.msgResut(res, this, 'getList');
      });
    }
  }
};